.LoaderButton .spinning.glyphicon {
  margin-right: 7px;
  top: 2px;
  animation: spin 1s infinite linear;
}
@keyframes spin {
  from {
    transform: scale(1) rotate(0deg);
  }
  to {
    transform: scale(1) rotate(360deg);
  }
}
@media all and (min-width: 149px) {
  .NewNotifLoaderButton {
    padding: 10px 0;
  }

  .NewNotifLoaderButton {
    margin: 0 auto;
    max-width: 149px !important;
   
  }
}
