/* @media all and (min-width: 680px) {
  .NewNotificationTopic {
    padding: 60px 0;
  }

  .NewNotificationTopic form {
    margin: 0 auto;
    max-width: 520px;
  }
} */

.NewNotificationTopidc form {
  margin-bottom: 15px;
  display: block;
  font-size: 14px;
}

@media all and (min-width: 149px) {
  .NewNotifLoaderButtonD {
    padding: 10px 0;
    cursor: not-allowed;
  }

  .NewNotifLoaderButtonD {
    margin: 0 auto;
    max-width: 149px !important;
    cursor: not-allowed;
  }
  .NewNotifLoaderButtonE {
    padding: 10px 0;
    cursor: not-allowed;
  }

  .NewNotifLoaderButtonE {
    margin: 0 auto;
    max-width: 149px !important;
    cursor: not-allowed;
  }
}
