.App {
  margin-top: 15px;
  margin-bottom: 50px;
}

.App .navbar-brand {
  font-weight: bold;
}
.banner {
  background-image: url("./images/header_bg.png");
}
.footer {

  text-align: center;
  background-color: tomato;
  color: white;
}
.content {
  flex: 1 0 auto;
  padding: 10px;
}